import axios from 'axios'

const coberturadefecto = {

    GetListar: () => axios.get(`cobertura-defecto-domicilios/listar-cobertura-defecto`),
    listarCedis: () => axios.get(`cobertura-defecto-domicilios/listar-cedis`),
    editarCoberturaCedis: (params) => axios.post(`cobertura-defecto-domicilios/editar-cobertura-cedis`, params),
    editarImpuestosInventario: (params) => axios.post(`cobertura-defecto-domicilios/editar-impuestos-inventario`, params),
}
export default coberturadefecto

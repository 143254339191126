<template>
    <section>
        <div
        class="py-4 custom-scroll overflow-auto"
        :style="`${$route.name == 'admin.tiendas.produccion.clientes' ? 'height: calc(100vh - 190px)' : $route.name == 'admin.clientes' ? 'height: calc(100vh - 75px)' : 'height: calc(100vh - 127px)' }`"
        >
            <template v-if="caso == 1 || caso == 4">
                <timercomponent v-model="tipo_fecha" @refresh="montar()" />
                <div class="bg-white py-3 br-12">
                    <div class="row mx-0 pl-4">
                        <div class="col-auto px-4 f-18 text-general f-600">
                            Tipo de {{ $config.cliente }}
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5">
                            <el-select v-model="id_tipo" placeholder="Seleccionar tipo" class="w-100" size="small" @change="agregarTipos">
                                <el-option
                                v-for="item in tiposClientes"
                                :key="item.id"
                                :label="item.tipo"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div class="col-auto">
                            <div class="bg-general br-8 text-white px-4 cr-pointer d-middle-center" style="height:32px;" @click="tiposRefrescar">
                                Cargar
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-5" />
                        <div class="col-auto px-5" />
                        <div class="col pl-2">
                            <div class="row mx-0">
                                <div class="col-12 d-middle px-4 my-1 mt-2">
                                    <el-checkbox v-model="sin_tipo" class="check-red">
                                        <span class="ml-1">Todos los {{ $config.cliente + 's' }} sin clasificación</span>
                                    </el-checkbox>
                                </div>
                                <div v-if="!sin_tipo" class="col-12 mt-1">
                                    <div class="row mx-0">
                                        <div v-for="(data,d) in tiposClientesSeleccionados" :key="d" class="bg-light-f5 br-20 px-2 border d-middle mr-1 f-14 mb-1">
                                            {{ data.tipo }} <i class="icon-cancel ml-3 f-15 text-general cr-pointer" @click="eliminarTipo(d)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-4" />
                <div class="row f-18 f-600 px-5 mx-0 text-general mb-3">
                    Relación de ventas con {{ $config.cliente + 's' }}
                </div>
                <div class="row mx-0 px-4 position-relative">
                    <!-- Filtro -->
                    <cargando v-if="cargandoComparativos" />
                    <div class="px-3 m-3">
                        <div class="row cr-pointer" :class="filtro_relacion==4?'text-general':''" @click="filtro_relacion = 4">
                            <p class="p-2">Hoy</p>
                            <div class="pt-1 w-100" :class="filtro_relacion==4?'bg-general3 br-12':'bg-whitesmoke'" />
                        </div>
                        <div class="row cr-pointer" :class="filtro_relacion==1?'text-general':''" @click="filtro_relacion = 1">
                            <p class="p-2">Última semana</p>
                            <div class="pt-1 w-100" :class="filtro_relacion==1?'bg-general3 br-12':'bg-whitesmoke'" />
                        </div>
                        <div class="row cr-pointer" :class="filtro_relacion==2?'text-general':''" @click="filtro_relacion = 2">
                            <p class="p-2">Último mes</p>
                            <div class="pt-1 w-100" :class="filtro_relacion==2?'bg-general3 br-12':'bg-whitesmoke'" />
                        </div>
                    </div>
                    <!-- Relaciones -->
                    <div v-for="(rel, idx) in relaciones" :key="idx" class="br-12 mx-2 my-1" :class="idx>1 && rel.ids.length > 0 ? 'cr-pointer' : ''" @click="modalRelacion(idx,rel.ids,rel.nombre)">
                        <div
                        class="br-12 p-2 bg-white text text-2 text-center px-4 bg-grr-red border"
                        style="width:150px;height:60px;"
                        >
                            {{ rel.nombre }}
                        </div>
                        <div
                        class="row mx-0 my-1 px-2"
                        :class="{ 'text-general':(rel.porcentaje>0), 'text-general-red':(rel.porcentaje<0), '':(rel.porcentaje==0) }"
                        >
                            <div class="col px-0 text-center">
                                <i :class="{ 'icon-up':(rel.porcentaje>0), 'icon-down':(rel.porcentaje<0), '':(rel.porcentaje==0) }" />
                                {{ rel.porcentaje>0?'+':'' }}{{ rel.porcentaje }}%
                            </div>
                        </div>
                        <div class="row mx-0 my-1 f-22 px-2 text-muted">
                            <div class="col px-0 text-center">
                                {{ idx == 1 ? separadorNumero(rel.valor,1) : agregarSeparadoresNumero(rel.valor) }}
                            </div>
                        </div>
                    </div>
                </div>
                <graficas ref="grafica" :tipo-fecha="tipo_fecha" />
            </template>
            <!-- <div class="row mx-3 mt-3 my-4 bg-white br-t-12 py-1 pt-3">
                <div class="col border-right">
                    <p class="text-general text-center f-500 f-18">
                        Clientes del {{ $config.vendedor }}
                    </p>
                </div>
                <div class="col border-right">
                    <p class="f-30 text-general text-center f-600">
                        {{ clientesLeechero.clientesTotales }}
                    </p>
                    <p class="text-general f-400 text-center f-14">
                        <i class="icon-cliente f-17" />
                        Clientes
                    </p>
                </div>
                <div class="col border-right">
                    <p class="f-30 text-general text-center f-600">
                        {{ clientesLeechero.clientesUnicos }}%
                    </p>
                    <p class="text-general f-400 text-center f-14">
                        <i class="icon-cliente f-17" />
                        Clientes únicos
                    </p>
                </div>
                <div class="col border-right">
                    <div class="row mx-0">
                        <div class="col-12">
                            <div v-if="$can('boton_leechero_clientes_agregar')" class="btn-outline px-4 f-14" @click="irAgregarLeecheros">
                                Agregar {{ $config.vendedor }} a otros clientes
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div v-if="$can('boton_leechero_clientes_ceder')" class="btn-outline px-4 f-14" @click="irCederClientes">
                                Ceder clientes a otro {{ $config.vendedor }}
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row mx-3 mt-3 f-600 bg-white br-t-12 text-general px-5 pt-3">
                Crecimiento de {{ $config.cliente + 's' }} en los últimos {{ chart_Crecimiento.dataset.source.length }} meses
            </div>
            <!-- Grafica -->
            <div class="position-relative">
                <cargando v-if="cargandoCrecimiento" />
                <chartBarras :datos="crecimientoClientes" :chart="chart_Crecimiento" />
            </div>
            <template v-if="caso == 1 || caso == 4">
                <div class="row f-18 f-600 px-5 mx-0 text-general mb-3 mt-5">
                    Rango de cobertura de los domicilios por tiendas:
                </div>
                <div class="p-3 f-15">
                    <div class="row mx-0 my-3 align-items-center justify-center">
                        <div class="col-auto">
                            <el-tooltip class="item" effect="light" :content="`${$config.vendedor}s registrados`" placement="bottom">
                                <div class="br-20 bg-general3 d-middle text-white justify-content-between" style="min-width:57px;">
                                    <i class="icon-storefront-outline mx-1  f-18" />
                                    <span class="pr-2">{{ total }}</span>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto">
                            <div class="br-20 bg-general d-middle text-white justify-content-between" style="min-width:57px;">
                                <i class="icon-ruler mx-1  f-18" />
                                <span class="pr-2">{{ maximo }}(m)</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-2">
                        <div v-if="chartCobertura.dataset.source.length" class="col position-relative">
                            <cargando v-if="cargandoCobertura" />
                            <echart :options="chartCobertura" />
                        </div>
                        <div v-else class="img-sindatos text-center" style="max-width:230px;">
                            No hay {{$config.vendedor}}s registradas que hayan configurado su cobertura
                        </div>
                    </div>
                </div>
            </template>
            <div class="row mx-3 my-3 mt-5">
                <div class="col-auto d-middle text-general f-15 f-500">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general cr-pointer text-white bg-general px-3 f-18 br-12" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoMetricas" mini />
            </div>
            <template v-if="caso == 1 || caso == 4">
                <div class="row mx-0 justify-center mb-4">
                    <div class="col-5">
                        <div class="row mx-0 justify-center">
                            <div class="d-middle bg-light-f5 shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="modalPrimeraVez(1)">
                                <div class="col-auto">
                                    <img :src="url_primera" alt="" />
                                </div>
                                <div class="col text-general f-17">
                                    {{ $config.cliente }} que ingresaron por primera vez al sistema
                                </div>
                                <div class="col-auto text-general d-middle-center f-20 f-600">
                                    {{ agregarSeparadoresNumero(primerosAccesos.length) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="row mx-0 justify-center">
                            <div class="d-middle bg-light-f5 shadow py-2 br-10 my-3 cr-pointer" style="max-width:450px;" @click="modalPrimeraVez(2)">
                                <div class="col-auto">
                                    <img :src="url_productos" alt="" />
                                </div>
                                <div class="col text-general f-17">
                                    {{ $config.cliente }} que realizaron su primera compra
                                </div>
                                <div class="col-auto text-general d-middle-center f-20 f-600">
                                    {{ agregarSeparadoresNumero(primerosPedidos.length) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 px-5 my-4 f-18 f-600 text-general">
                    Compras por tipo de {{ $config.cliente }}
                </div>
                <chartBarras :datos="pedidosTipos" :chart="chart_tipos" />
                <div class="row mx-0 px-5 my-4 f-18 f-600 text-general">
                    Pedidos recibidos por cedis
                </div>
                <chartBarras :datos="pedidosCedis" :chart="chart_cedis" />
                <div class="row mx-0 px-5 my-4 f-18 f-600 text-general">
                    Pedidos recibidos por las {{ $config.vendedor }} de acuerdo a la distancia de los clientes
                </div>
                <chartBarras :datos="pedidosRecibidos" :chart="chart_pedidos" />
                <div class="row mx-0 px-5 py-3 mb-5">
                    <div class="col-12">
                        <p class="text-general f-18 f-600">
                            Categorias mas vendidas
                        </p>
                    </div>
                    <div class="col px-5 py-3 mb-5">
                        <div class="row mx-0">
                            <div v-for="(data, d) in categorias_dinero.slice(0,8)" :key="d" class="col-auto px-2">
                                <div class="br-12 border d-middle-center px-3 f-12" style="width:125px;height:75px;background-color:#F8F9FF;">
                                    {{ data.nombre }}
                                </div>
                                <p class="text-center my-2">
                                    {{ separadorNumero(data.total) }}
                                </p>
                                <p class="text-center text-gr-general f-600 my-2">
                                    {{ agregarSeparadoresNumero(data.porcentaje_total,1) }} %
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="categorias_dinero.length > 8" class="col-auto mt-4">
                        <div
                        class="border bg-general3 text-white f-18 br-12 px-4 text-center py-2 cr-pointer"
                        @click="verCategorias('Categorias mas vendidas')"
                        >
                            Ver Más <br /> <i class="icon-angle-right" />
                        </div>
                    </div>
                </div>
                <!--*----*----* Compras por genero *----*----*-->
                <div class="row mx-0 px-5 f-600 f-18 my-3 text-general">
                    Compradores por género
                </div>
                <div class="row mx-0 my-2 px-5">
                    <div class="col">
                        <div class="row br-5" style="height:30px;background-color:#EBEBEB;">
                            <template v-if="datos == true">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${generos[0].porcentaje}%;`" />
                                <div class="h-100 bg-general2" :style="`width:${generos[1].porcentaje}%;`" />
                                <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${generos[2].porcentaje}%;`" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center my-3">
                    <!-- Compradores Mujer -->
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/person (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    Mujer {{ agregarSeparadoresNumero(generos[0].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(generos[0].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(generos[0].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Compradores Hombre -->
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/person (2).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    Hombre {{ agregarSeparadoresNumero(generos[1].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(generos[1].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(generos[1].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Compradores sin definir -->
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/astronaut (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    Otro {{ agregarSeparadoresNumero(generos[2].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(generos[2].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(generos[2].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 px-5 text-general f-600 f-18 my-3">
                    Compradores por edad
                </div>
                <div class="row mx-0 px-5">
                    <div class="col">
                        <div class="row br-5" style="height:30px;background-color:#EBEBEB;">
                            <el-tooltip v-for="(data, i) in compradores_edad" :key="i" placement="top" :content="`${data.edad}: ${separadorNumero(data.total)}`" effect="light">
                                <div v-if="data.estado != 11" class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5" :class="backgroundEdad(data.estado)" :style="`width:${data.porcentaje}%;`" />
                                <div v-else class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5 bg-dark" :style="`width:${data.porcentaje}%;`" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 px-5 mt-3 justify-center">
                    <div v-for="(data, i) in compradores_edad" :key="i" class="col-2 px-2 my-2 text-muted2" style="min-height:95px;">
                        <div class="w-100 h-100 border br-12 px-3">
                            <p class="f-17 tres-puntos py-1">
                                <span class="f-600" :class="estiloEdad(data.estado)"> {{ data.edad }}:   {{ data.porcentaje }}% </span>
                            </p>
                            <p class="f-14 tres-puntos py-1">
                                {{ separadorNumero(data.total) }}
                            </p>
                            <p class="f-14 tres-puntos py-1">
                                {{ agregarSeparadoresNumero(data.cantidad) }} Pedidos
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Compras por genero -->
                <div class="row mx-0 px-5 my-3 text-general f-600 f-18">
                    Compradores por dispositivo
                </div>
                <div class="row mx-0 my-2 px-5">
                    <div class="col">
                        <div class="row br-5" style="height:30px;background-color:#EBEBEB;">
                            <template v-if="datos == true">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${dispositivos[0].porcentaje}%;`" />
                                <div class="h-100 bg-bg-general2" :style="`width:${dispositivos[1].porcentaje}%;`" />
                                <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${dispositivos[2].porcentaje}%;`" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 justify-content-center">
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/android.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    Android {{ agregarSeparadoresNumero(dispositivos[0].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(dispositivos[0].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(dispositivos[0].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/apple.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    iOS {{ agregarSeparadoresNumero(dispositivos[1].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(dispositivos[1].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(dispositivos[1].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                            <img src="/img/icons/world-wide-web.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                            <div class="px-2 ">
                                <p class="mb-0 f-18 text-general f-600">
                                    Web {{ agregarSeparadoresNumero(dispositivos[2].porcentaje,2) }}%
                                </p>
                                <div class="row mx-0">
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ separadorNumero(dispositivos[2].total) }}
                                    </div>
                                    <div class="col-auto text-general2 f-14 px-2">
                                        {{ agregarSeparadoresNumero(dispositivos[2].cantidad) }} Pedidos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-6 ml-auto mx-0 pt-5 px-5" style="width:170px;">
                        <p class="mb-0 text-general text-right f-18">
                            {{ agregarSeparadoresNumero(total_clientes_paginado) }}/{{ agregarSeparadoresNumero(total_clientes) }} {{ $config.cliente + 's' }}
                        </p>
                    </div>
                </div>
            </template>

            <!-- Tabla -->
            <div class="row mx-3 mt-3 bg-white pb-3 br-12 justify-center align-items-center">
                <div class="col-12 px-0">
                    <tabla-general :data="metricas_created.clientes" class-header="text-general f-500 f-16" alto="500">
                        <template slot="cabecera-izquierda">
                            <div class="col-auto text-general f-18 f-600 py-3">
                                Comportamiento de compra de los {{ $config.cliente + 's' }}
                            </div>
                        </template>
                        <template v-slot:adicionales-derecha>
                            <el-table-column prop="nombre" label="Nombre" sortable min-width="250" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2">
                                        {{ scope.row.nombre || "No registra" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="telefono" label="Teléfono" sortable min-width="120" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ scope.row.telefono || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="cantidad_total" label="Pedidos totales" sortable min-width="180" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ scope.row.cantidad_total || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="total_total" label="Compras totales" sortable min-width="180" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ convertMoney(scope.row.total_total) || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="cantidad_fecha" label="Pedidos a la fecha" sortable min-width="200" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ scope.row.cantidad_total || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="total_fecha" label="Compras a la fecha" sortable min-width="200" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ convertMoney(scope.row.total_fecha) || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="promedio" label="Ticket promedio fecha" sortable min-width="250" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ convertMoney(scope.row.promedio) }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="ultima_compra_Valor" label="Valor últ. compra" sortable min-width="220" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ convertMoney(scope.row.ultima_compra_Valor) || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="ultima_compra_id" label="Últ. compra" sortable min-width="150" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ scope.row.ultima_compra_id || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="ultima_compra_fecha" label="Últ. compra fecha" sortable min-width="200" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ formatearFecha(scope.row.ultima_compra_fecha) || "-" }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="creacion" label="Registro" sortable min-width="120" class-name="text-general f-600">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                    <p class="text-general2 text-center">
                                        {{ formatearFecha(scope.row.creacion) }}
                                    </p>
                                <!-- </router-link> -->
                                </template>
                            </el-table-column>
                        </template>
                    </tabla-general>
                </div>
            </div>
            <!-- Tabla -->
            <div class="row mx-0 justify-content-end px-5 my-3">
                <div class="col-10" />
                <div class="col-auto ml-3">
                    <p class="mb-0 text-general f-18">
                        {{ agregarSeparadoresNumero(total_clientes) }} {{ $config.cliente + 's' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal-ver-categorias ref="modalVerCategorias" />
        <modal-info-clientes ref="infoClientes" />
        <modalDetalleRelacion ref="modalDetalleRelacion" />
        <modal-compra-ingreso ref="modalCompraIngreso" :titulo="`${$config.cliente}  que ingresaron y/o compraron por primera vez`" />
        <modalAgregarLeecheros ref="modalAgregarLeecheros" />
        <modalCederClientes ref="modalCederClientes" @actualizar="getClientesLeechero" />
        <!-- Modales -->
    </section>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import echarts from 'echarts'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general2')
let colorGeneral3 = getComputedStyle(document.documentElement).getPropertyValue('--text-general')
let colorGeneral4 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

import Metricas from "../../services/metricas"
import Clientes from "../../services/clientes"
import CoberturaDefecto from '~/services/configurar/admin/CoberturaDefecto'


export default {
    components: {
        modalInfoClientes: () => import ('./partials/modalInfoClientes'),
        graficas: () => import ('./graficas'),
        modalDetalleRelacion: () => import ('./partials/modalDetalleRelacion'),
        modalCompraIngreso: () => import ('../estadisticas/accesoUsuarios/partials/modalCompraIngreso'),
        modalAgregarLeecheros: () => import('./partials/modalAgregarLeecheros'),
        modalCederClientes: () => import('./partials/modalCederClientes'),
    },
    data(){
        return{
            dataColumns: [
                { titulo: 'Nombre', valor: 'nombre' },
                { titulo: 'Télefono', valor: 'telefono', class: 'text-center' },
                { titulo: 'Pedidos totales', valor: 'pedidos_totales', class: 'text-center' },
                { titulo: 'Compras totales', valor: 'compras_totales', class: 'text-center'},
                { titulo: 'Pedidos en la fecha', valor: 'pedidos_fecha', ancho: 250, class: 'text-center' },
                { titulo: 'Compras en la fecha', valor: 'compras_fecha', ancho: 250, class: 'text-center' },
                { titulo: 'Ticket promedio fecha', valor: 'ticket_promedio', ancho: 250, class: 'text-center' },
                { titulo: 'Últ. compra', valor: 'ult_compra', class: 'text-center' },
                { titulo: 'Valor últ. compra', valor: 'valor_compra', class: 'text-center' },
                { titulo: 'Fecha últ. compra', valor: 'fecha_compra', ancho: 200, class: 'text-center' },
                { titulo: 'Registro', valor: 'registro', class: 'text-center'}
            ],
            cargandoComparativos: true,
            cargandoCrecimiento: true,
            cargandoCobertura: true,
            cargandoMetricas: true,
            order: true,
            filtro_relacion: 1,
            tipo_fecha: 1,
            tipo_relacion: 1,
            relaciones: [
                { nombre: 'Cantidad de pedidos', porcentaje: 8.7, valor: 145, ids: [] },
                { nombre: 'Ticket promedio', porcentaje: -12.5, valor: 5457000, ids: [] },
                { nombre: 'Total clientes', porcentaje: -12.5, valor: 15, ids: [] },
                { nombre: 'Clientes nuevos', porcentaje: 5.4, valor: 18, ids: [] },
                { nombre: 'Clientes recurrentes', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Clientes perdidos', porcentaje: -4.1, valor: 4, ids: [] },
            ],
            clicksPedidos: 1,
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            pedidosTipos: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Tipo con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Tipo con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            pedidosCedis: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Cedis con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Cedis con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            pedidosRecibidos: [
                { nombre: 'Ventas totales', valor: 0, color: 'bg-gr-general', formato: 1 },
                { nombre: 'Rango con mayor # de ventas', valor: 0, color: 'bg-gr-pink', formato: 1 },
                { nombre: 'Pedidos totales', valor: 0, color: 'bg-gr-red', formato: 2 },
                { nombre: 'Rango con mayor # de pedidos', valor: 0, color: 'bg-gr-purple', formato: 2 }
            ],
            crecimientoClientes: [
                { nombre: 'Clientes totales', valor: 0, color: 'bg-gr-general', formato: 2 },
                { nombre: 'Mes con mayor crecimiento', valor: 0, fecha: '' ,color: 'bg-gr-pink', formato: 2 }
            ],
            chart_Crecimiento : {
                backgroundColor: '#fff',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            show: true,
                            backgroundColor: '#fff',
                            color: '#556677',
                            borderColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(0,0,0,0)',
                            shadowOffsetY: 0
                        },
                        lineStyle: {
                            width: 1
                        }
                    },
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#5c6c7c'
                    },
                    padding: [5, 5],
                    extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
                },
                xAxis: [
                    {
                        type: 'category',
                        // data: ['Ener', 'Feb', 'Mar', 'abril', 'may','jun'],
                        axisLine: {
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#556677'
                            },
                            fontSize: 12,
                            margin: 15
                        },
                        axisPointer: {
                            label: {
                                padding: [0, 0, 10, 0],
                                margin: 15,
                                fontSize: 12,
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#fff'
                                    }, {

                                        offset: 0.86,
                                        color: '#fff'
                                    }, {
                                        offset: 0.86,
                                        color: colorGeneral
                                    }, {
                                        offset: 1,
                                        color: colorGeneral
                                    }],
                                    global: false
                                }
                            }
                        },
                        boundaryGap: false
                    }
                ],
                yAxis: [
                    {
                        name: 'Clientes',
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        splitLine: {
                            show: true
                        }
                    }, {
                        type: 'value',
                        position: 'right',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            },
                            formatter: '{value}'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        splitLine: {
                            show: false
                        }
                    }
                ],
                dataset:{
                    dimensions:['fecha','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Crecimiento',
                        type: 'line',
                        // data: [10, 10, 14, 12, 15, 3, 7],
                        symbol: 'circle',
                        symbolSize: 10,
                        smooth: true,
                        yAxisIndex: 0,
                        showSymbol: false,
                        lineStyle: {
                            width: 5,
                            color: colorGeneral,
                            shadowColor: 'rgba(158,135,255, 0.3)',
                            shadowBlur: 10,
                            shadowOffsetY: 20
                        },
                        itemStyle: {
                            normal: {
                                color:  new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: colorGeneral
                                },
                                {
                                    offset: 1,
                                    color: colorGeneral
                                }
                                ]),
                                borderColor: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: colorGeneral
                                },
                                {
                                    offset: 1,
                                    color: colorGeneral
                                }
                                ])
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#E9E9E9'
                                },
                                {
                                    offset: 1,
                                    color: '#F3F3F3'
                                }
                                ], false)
                            }
                        },
                    }
                ]
            },
            chart_pedidos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
						name: 'Distancia',
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['distancia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            chart_tipos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            chart_cedis: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#c0ccd7'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    },
                    {
                        nameTextStyle: {
                            color: '#6D6B6B'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 1, color: colorGeneral4},
                                    {offset: 0.2, color: colorGeneral4 },
                                ]
                            )
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        },
                    }
                ]
            },
            clientes: [],
            clientes_paginado: [],
            categorias_dinero: [],
            total_clientes: 0,
            total_clientes_paginado: 0,
            total_por_pagina: 0,
            semana:{},
            ultimo_mes:{},
            ultimo_trimestre:{},
            hoy:{},
            total:0,
            maximo:0,
            chartCobertura: {
                tooltip: {
                    trigger: 'axis',
                    show: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    name: 'Metros',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDBDB',
                            type: 'dashed'
                        }
                    },
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value',
                    name: 'Vendedores'
                },
                series: [{
                    // data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: colorGeneral3 },
                                {offset: 1, color: colorGeneral3 },
                            ]
                        )
                    },
                    lineStyle: {
                        color: colorGeneral3
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.3, color: '#E3E3E3'},
                                {offset: 0.2, color: '#E3E3E3' },
                                {offset: 1, color: '#fff' },
                            ]
                        )
                    }
                },
                {
                    // data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: colorGeneral },
                                {offset: 1, color: colorGeneral },
                            ]
                        )
                    },
                    lineStyle: {
                        color: colorGeneral
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.3, color: colorGeneral},
                                {offset: 0.2, color: '#FFF4F4' },
                                {offset: 1, color: '#ffffff' },
                            ]
                        )
                    }
                }
                ],
                dataset:{
                    dimensions: ['valor','tiendas','tiendas_cliente'],
                    source:[]

                }
            },
            datos: true,
            compradores_edad: [
                { edad: '-20', estado: 1, cantidad: 6, porcentaje: 5, total : 0},
                { edad: '20-25', estado: 2, cantidad: 8 , porcentaje: 5, total : 0},
                { edad: '25-30', estado: 3, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '30-35', estado: 4, cantidad: 3 , porcentaje: 10, total : 0},
                { edad: '35-40', estado: 5, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '40-45', estado: 6, cantidad: 14 , porcentaje: 10, total : 0},
                { edad: '45-50', estado: 7, cantidad: 25 , porcentaje: 10, total : 0},
                { edad: '50-55', estado: 8, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '55-60', estado: 9, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '+60', estado: 10, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: 'Sin Determinar', estado: 11, cantidad: 12 , porcentaje: 10, total : 0},
            ],
            generos: [
                {
                    nombre: "Femenino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Masculino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Otro",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            dispositivos: [
                {
                    nombre: "Android",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "iOS",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Web",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            primeraVez: true,
            sin_tipo: false,
            metricas_created: {cliente:[]},
            metricas_entregas: {},
            id_tipo: null,
            ids_tipos: [],
            tiposClientes: [],
            tiposClientesSeleccionados: [],
            url_primera: '/img/ilustraciones/ingresos.svg',
            url_productos: '/img/ilustraciones/Productos.svg',
            primerosAccesos: [],
            primerosPedidos: [],
            clientesLeechero: {
                clientesTotales: 0,
                clientesUnicos: 0,
            },
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        caso(){
            let caso = 0
            switch (this.$usuario.rol_nombre){
            case 'admin-vip':
                if (this.$route.params.id_tienda){
                    caso = 2
                } else {
                    caso = 1
                }
                break;
            case 'tendero-vip':
                caso = 3
                break;
            case 'admin':
                if (this.$route.params.id_tienda){
                    caso = 5
                } else {
                    caso = 4
                }
                break;

            default:
                break;
            }
            return caso
        },
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                if (this.caso == 4 || this.caso == 1){
                    id_tienda = 0
                } else {
                    id_tienda = this.$usuario.tienda.id
                }
            }

            return id_tienda
        },
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoMetricas &&
                    (this.$refs.grafica && !this.$refs.grafica.loading1) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading2) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading3)
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        filtro_relacion(val){
            this.calcularComparativos()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        tipo_fecha(){
            this.calcularComparativos()
            this.calcularInforme()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
                this.cargandoCrecimiento = true
                this.cargandoCobertura = true
            }
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.crecimiento()
                        if (this.caso == 1 || this.caso == 4){
                            await this.listaTiposClientes()
                            await this.comparativos()
                            setTimeout( async() => {
                                await this.$refs.grafica.montar(this.ids_tipos, this.sin_tipo)
                            }, 500);
                            await this.ListarCoberturaDefecto()
                        }
                        await this.informe()
                    }, 1000);
                }else{
                    await this.crecimiento()
                    if (this.caso == 1 || this.caso == 4){
                        await this.listaTiposClientes()
                        await this.comparativos()
                        setTimeout( async() => {
                            await this.$refs.grafica.montar(this.ids_tipos)
                        }, 500);
                        await this.ListarCoberturaDefecto()
                    }
                    await this.informe()
                }
                this.getClientesLeechero();
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        estiloEdad(estado){
            switch(estado){
            case 1:
                return 'text-orange-clear_2'
            case 2:
                return 'text-green'
            case 3:
                return 'text-purple'
            case 4:
                return 'text-orange-clear'
            case 5:
                return 'text-dark-blue'
            case 6:
                return 'text-blue-light'
            case 7:
                return 'text-pink-light'
            case 8:
                return 'text-dark-green'
            case 9:
                return 'text-blue'
            case 10:
                return 'text-dark-pink'
            case 11:
                return 'text-dark'
            }
        },
        backgroundEdad(estado){
            switch(estado){
            case 1:
                return 'bg-orange-clear_2'
            case 2:
                return 'bg-green'
            case 3:
                return 'bg-purple'
            case 4:
                return 'bg-orange-clear'
            case 5:
                return 'bg-dark-blue'
            case 6:
                return 'bg-blue-light'
            case 7:
                return 'bg-pink-light'
            case 8:
                return 'bg-dark-green'
            case 9:
                return 'bg-blue'
            case 10:
                return 'bg-dark-pink'
            case 11:
                return 'bg-dark'
            /* case 10:
                return 'text-green' */
            }
        },
        iconFiltro(dato){
            if (this.clicksPedidos === dato){
                return this.order ? 'flecha-orden text-general abajo mover-abajo' : 'flecha-orden girar text-general arriba mover-arriba'
            } else {
                return 'text-gris'
            }
        },
        containerClass(dato){
            if (this.clicksPedidos === dato){
                return this.order ? ' text-general abajo' : 'girar text-general arriba'
            } else {
                return 'text-gris'
            }
        },
        async ListarCoberturaDefecto(){
            try {
                const {data} = await CoberturaDefecto.GetListar()
                this.chartCobertura.dataset.source = data.barras
                this.total = data.total
                this.maximo = data.maximo
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargandoCobertura = false
            }
        },
        AbrirInfoClientes(cliente){
            this.$refs.infoClientes.toggle(cliente,this.caso,this.id_tienda);
        },
        ordenar(tipo){
            this.clicksPedidos = tipo
            let mismoTipo = tipo == this.clicksPedidos
            if (mismoTipo){
                this.order = !this.order
            }else{
                this.order = orden == 'desc'
            }
            let ordenar = this.order ? 'desc' : 'asc'
            switch (tipo){
            case 1:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 2:
                this.clientes_paginado = _.orderBy(this.clientes,'total_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 3:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 4:
                this.clientes_paginado = _.orderBy(this.clientes,'total_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 5:
                this.clientes_paginado = _.orderBy(this.clientes,'promedio_fecha',ordenar).slice(0,this.total_por_pagina)
                break;

            default:
                break;
            }
        },
        agregar_mas_clientes(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina += 20
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        verTodos(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina = this.total_clientes
                this.clientes_paginado = this.clientes
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        async crecimiento(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    caso: this.caso,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                }
                const { data } = await Clientes.crecimiento(params)
                this.chart_Crecimiento.dataset.source = data.data.clientes
                this.crecimientoClientes[0].valor = data.data.total_clientes
                this.crecimientoClientes[0].nombre = `${this.$config.cliente + 's' } totales`
                this.crecimientoClientes[1].valor = data.data.crecimiento_mayor ? data.data.crecimiento_mayor.cantidad : 0
                this.crecimientoClientes[1].fecha = data.data.crecimiento_mayor ? data.data.crecimiento_mayor.fecha : 'No registra'

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoCrecimiento = false
            }
        },
        async listaTiposClientes(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_cedis: this.id_cedis,
                }
                const { data } = await Clientes.listaTiposClientes(params)
                this.tiposClientes = data.tipos
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async informe(){
            try {

                let params = {
                    id_tienda: this.id_tienda,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    caso: this.caso,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                }
                const { data } = await Clientes.informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas
                this.primerosAccesos = data.data.primerosAccesos
                this.primerosPedidos = data.data.primerosPedidos

                // this.notificacion('Mensaje','Informe Actualizado correctamente','success')
                this.total_por_pagina = 10

                this.calcularInforme()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        calcularInforme(){
            try {
                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created
                if (this.caso == 1 || this.caso == 4){

                    this.chart_tipos.dataset.source = datos.tiposVentas
                    this.chart_cedis.dataset.source = datos.cedisVentas
                    this.chart_pedidos.dataset.source = datos.distancias
                    this.pedidosRecibidos[0].valor = datos.distancia_total
                    this.pedidosRecibidos[1].valor = datos.distancia_total_max ? datos.distancia_total_max.total : 0
                    this.pedidosRecibidos[1].fecha = datos.distancia_total_max ? datos.distancia_total_max.distancia : 'No registra'
                    this.pedidosRecibidos[2].valor = datos.distancia_cantidad
                    this.pedidosRecibidos[3].valor = datos.distancia_cantidad_max ? datos.distancia_cantidad_max.cantidad : 0
                    this.pedidosRecibidos[3].fecha = datos.distancia_cantidad_max ? datos.distancia_cantidad_max.distancia : 'No registra'
                    this.pedidosTipos[0].valor = datos.maxTiposVentas.totalVentas
                    this.pedidosTipos[1].valor = datos.maxTiposVentas.maxVentas ? datos.maxTiposVentas.maxVentas.total : 0
                    this.pedidosTipos[1].fecha = datos.maxTiposVentas.maxVentas ? datos.maxTiposVentas.maxVentas.nombre : 'No registra'
                    this.pedidosTipos[2].valor = datos.maxTiposVentas.totalCantidad
                    this.pedidosTipos[3].valor = datos.maxTiposVentas.maxCantidad ? datos.maxTiposVentas.maxCantidad.cantidad : 0
                    this.pedidosTipos[3].fecha = datos.maxTiposVentas.maxCantidad ? datos.maxTiposVentas.maxCantidad.nombre : 'No registra'
                    this.pedidosCedis[0].valor = datos.maxCedisVentas.totalVentas
                    this.pedidosCedis[1].valor = datos.maxCedisVentas.maxVentas ? datos.maxCedisVentas.maxVentas.total : 0
                    this.pedidosCedis[1].fecha = datos.maxCedisVentas.maxVentas ? datos.maxCedisVentas.maxVentas.nombre : 'No registra'
                    this.pedidosCedis[2].valor = datos.maxCedisVentas.totalCantidad
                    this.pedidosCedis[3].valor = datos.maxCedisVentas.maxCantidad ? datos.maxCedisVentas.maxCantidad.cantidad : 0
                    this.pedidosCedis[3].fecha = datos.maxCedisVentas.maxCantidad ? datos.maxCedisVentas.maxCantidad.nombre : 'No registra'

                    this.generos = datos.generos
                    this.compradores_edad = datos.edades
                    this.dispositivos = datos.dispositivos
                    this.categorias_dinero = datos.categorias
                }
                this.clientes = datos.clientes
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes = datos.total_clientes
                this.total_clientes_paginado = this.clientes_paginado.length


            } catch (error){
                console.log(error);
            }
        },
        //comparativos
        modalRelacion(idx,ids,titulo){
            if (idx>1 && ids.length > 0){
                let filtro = ''
                switch (this.filtro_relacion){
                case 1:
                    filtro = "Durante última semana"
                    break;
                case 2:
                    filtro = "Durante último mes"
                    break;
                case 3:
                    filtro = "Durante último trimestre"
                    break;
                default:
                    break;
                }
                this.$refs.modalDetalleRelacion.toggle(ids,titulo,filtro)
            }
        },
        async comparativos(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    ids_tipos: this.ids_tipos,
                    sin_tipo: this.sin_tipo,
                }
                const { data } = await Metricas.comparativos(params)
                this.$validar(data)

                this.semana_created = data.data.semana.pedidos_created
                this.ultimo_mes_created = data.data.ultimo_mes.pedidos_created
                this.hoy_created = data.data.hoy.pedidos_created
                this.semana_entregas = data.data.semana.pedidos_entregas
                this.ultimo_mes_entregas = data.data.ultimo_mes.pedidos_entregas
                this.hoy_entregas = data.data.hoy.pedidos_entregas

                this.calcularComparativos()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                this.semana = this.tipo_fecha == 1 ? this.semana_entregas : this.semana_created
                this.ultimo_mes = this.tipo_fecha == 1 ? this.ultimo_mes_entregas : this.ultimo_mes_created
                this.hoy = this.tipo_fecha == 1 ? this.hoy_entregas : this.hoy_created

                let tipo = {}
                switch (this.filtro_relacion){
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                case 4:
                    tipo = this.hoy
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.promedio.porcentaje
                this.relaciones[1].valor = tipo.promedio.valor
                this.relaciones[2].porcentaje = tipo.tenderos.porcentaje
                this.relaciones[2].valor = tipo.tenderos.valor
                this.relaciones[2].ids = tipo.tenderos.id
                this.relaciones[2].nombre = `Total ${this.$config.cliente}`

                this.relaciones[3].nombre = `${this.$config.cliente} nuevos`
                this.relaciones[3].porcentaje = tipo.nuevos.porcentaje
                this.relaciones[3].valor = tipo.nuevos.valor
                this.relaciones[3].ids = tipo.nuevos.id

                this.relaciones[4].nombre = `${this.$config.cliente} recurrentes`
                this.relaciones[4].porcentaje = tipo.recurrentes.porcentaje
                this.relaciones[4].valor = tipo.recurrentes.valor
                this.relaciones[4].ids = tipo.recurrentes.id

                this.relaciones[5].nombre = `${this.$config.cliente} perdidos`
                this.relaciones[5].porcentaje = tipo.perdidos.porcentaje
                this.relaciones[5].valor = tipo.perdidos.valor
                this.relaciones[5].ids = tipo.perdidos.id
            } catch (error){

            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.informe()

            } catch (error){
                console.log(error)
            }
        },
        async tiposRefrescar(){
            try {
                this.tipo = 1
                this.cargando()
                await this.montar()

            } catch (error){
                console.log(error)
            }
        },
        modalPrimeraVez(tipo){
            let ids = tipo == 1 ? this.primerosAccesos : this.primerosPedidos
            let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
            let fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            if (ids.length == 0) return
            this.$refs.modalCompraIngreso.toggle(ids, this.id_moneda, 2, fecha);
        },
        eliminarTipo(id){
            const spliced = this.tiposClientesSeleccionados.splice(id,1)
            this.ids_tipos.splice(id,1)
        },
        agregarTipos(val){
            let tipo = this.tiposClientes.find(el => el.id == val)
            this.ids_tipos.push(val)
            this.tiposClientesSeleccionados.push(tipo)
            this.id_tipo = null
        },
        verCategorias(titulo){
            this.$refs.modalVerCategorias.toggle({ categorias: this.categorias_dinero, titulo});
        },
        async getClientesLeechero(){
            try {
                const { data } = await Clientes.getClientesLeechero(this.id_tienda)
                this.clientesLeechero = data.clientes;
            } catch(error){
                this.error_catch(error)
            }
        },
        irAgregarLeecheros(){
            this.$refs.modalAgregarLeecheros.toggle();
        },
        irCederClientes(){
            this.$refs.modalCederClientes.toggle(this.clientesLeechero);
        },
    }
}
</script>
<style lang="scss">
.bg-fondo{
    background-color: #F9FBFC;
}
.br-12{
    border-radius: 12px;
}
.text-pink{
    color: #D6017B;
}
.text-blue{
    color:#0892DD;
}
.text-orange{
    color: #FF612B;
}
.text-purple{
    color: #9508EB;
}

.text-orange-clear_2{ color: #FFB87A;}
.text-orange-clear{ color: #FFDF75; }
.text-green{ color: #02D6BC;}
.text-dark-blue{ color: #284367;}
.text-blue-light{ color: #0892DD ;}
.text-pink-light{ color:#FFB1D0; }
.text-dark-green{ color: #03BB83;}
.text-blue{ color: #6BD1FF;}
.text-dark-pink{ color:#E33FC3;}

.bg-orange-clear_2{ background-color: #FFB87A;}
.bg-orange-clear{ background-color: #FFDF75; }
.bg-green{ background-color: #02D6BC;}
.bg-dark-blue{ background-color: #284367;}
.bg-blue-light{ background-color: #0892DD ;}
.bg-pink-light{ background-color:#FFB1D0; }
.bg-dark-green{ background-color: #03BB83;}
.bg-blue{ background-color: #6BD1FF;}
.bg-dark-pink{ background-color:#E33FC3;}


.bg-blue-clear{background-color: #03D6BC;}
.bg-blue-dark{ background-color: #284367;}
.bg-dark{ background-color: #333435;}
.text-dark{ color: #333435;}
.text-blue-clear{ color: #03D6BC; }
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}

</style>

import axios from "axios";

const API = "metricas";

const metricas = {
    ventas_mensuales(params){
        return axios(`${API}/ventas-mensuales`, { params });
    },
    ventas_diarias(params){
        return axios(`${API}/ventas-diarias`, { params });
    },
    calificaciones_mensuales(params){
        return axios(`${API}/calificaciones-mensuales`, { params });
    },
    productos_favoritos(params){
        return axios(`${API}/productos-favoritos`, { params });
    },
    ver_mas_productos(params){
        return axios(`${API}/ver-mas-productos`, { params });
    },
    metricas_informe(params){
        return axios(`${API}/metricas-informe`, { params });
    },
    comparativos(params){
        return axios(`${API}/comparativos`, { params });
    },
    info_tenderos(params){
        return axios.post(`${API}/info-tenderos`,params);
    },
};

export default metricas;
